import {useState, useLayoutEffect, MutableRefObject} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

interface Size {
    width: number;
    height: number;
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export default function useElementSize(target: MutableRefObject<HTMLElement | null>): Size {
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    });

    useLayoutEffect(() => {
        if (target && target.current) {
            const {
                width, height, top, bottom, left, right
            } = target.current.getBoundingClientRect();

            setSize({
                width, height, top, bottom, left, right
            });
        }
    }, [target?.current]);

    // entry.contentRect does not reflect to scale applied to element. We need to get getBoundingClientRect()
    useResizeObserver(target, ({target: observedTarget}) => {
        const {
            width, height, top, bottom, left, right
        } = observedTarget.getBoundingClientRect();

        setSize({
            width, height, top, bottom, left, right
        });
    });

    return size;
}
