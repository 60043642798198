/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import httpClient from '../../utils/httpClient';
// eslint-disable-next-line import/no-cycle
import {deleteFetchDocumentEque} from '../Designer/designerSlice';
import {PACKAGE_TYPES, BASE_PATH} from '../../../constants';
import {
    updatePackage,
    createPackageRequest,
    updatePackageDocuments,
    updatePackageDocument,
    deletePackageDocument,
    uploadDocumentChunks,
    savePackageDataAttr
} from '../../utils/requests/packages';
import {getErrorCode, getErrorMessage} from '../../utils/requests/error';
import getDocumentsFormDataChunks from '../../utils/getDocumentsFormDataChunks';
import {mapRemindersFromCamelToSnakeCase, mapRemindersFromSnakeToCamelCase} from '../Packages/PackageSettings/Reminders/utils';

const TEMPLATE_STORE_PATH = 'template.template';

export const templateSlice = createSlice({
    name: 'template',
    initialState: {
        template: {},
        //
        reminderConfig: {},
        isUpdatingReminderConfig: false,
        isFetchingReminderConfig: false,
        updateReminderConfigError: null,
        //
        templateError: null,
        isTemplateFetching: false,
        isCreatingTemplate: false,
        creatingTemplateError: null,
        uploadErrors: null,
        // documents
        isDocumentsFetching: false,
        documentsFetchError: null,
        isUploadingDocuments: false,
        uploadDocumentsError: null,
        isUpdatingDocuments: false,
        updateDocumentsError: null,
        isUpdatingDocument: false,
        updateDocumentError: null,
        isDeletingDocument: false,
        deleteDocumentError: null,
        // settings
        isUpdatingSettings: false,
        updateSettingsError: null,
        auditEvents: {},
        isAuditEventsFetching: false,
        rolesForLoadingPlaceholders: null,
        isSenderVisibleForLoadingPlaceholders: null
    },
    reducers: {
        setTemplate: (state, action) => {
            state.template = action.payload;
        },
        setDocuments: (state, action) => {
            state.template.documents = action.payload;
        },
        setRoles: (state, action) => {
            state.template.roles = action.payload;
        },
        setTemplateError: (state, action) => {
            state.templateError = action.payload;
        },
        setTemplateResponseStatusCode: (state, action) => {
            state.templateResponseStatusCode = action.payload;
        },
        setIsTemplateFetching: (state, action) => {
            state.isTemplateFetching = action.payload;
        },
        setIsCreatingTemplate: (state, action) => {
            state.isCreatingTemplate = action.payload;
        },
        setCreatingTemplateError: (state, action) => {
            state.creatingTemplateError = action.payload;
        },
        setUploadErrors: (state, action) => {
            state.uploadErrors = action.payload;
        },
        // documents
        setIsDocumentsFetching: (state, action) => {
            state.isDocumentsFetching = action.payload;
        },
        setDocumentsFetchError: (state, action) => {
            state.documentsFetchError = action.payload;
        },
        setTransactionError: (state, action) => {
            state.transactionError = action.payload;
        },
        setIsUploadingDocuments: (state, action) => {
            state.isUploadingDocuments = action.payload;
        },
        setUploadDocumentsError: (state, action) => {
            state.uploadDocumentsError = action.payload;
        },
        setIsUpdatingDocuments: (state, action) => {
            state.isUpdatingDocuments = action.payload;
        },
        setUpdateDocumentsError: (state, action) => {
            state.updateDocumentsError = action.payload;
        },
        setIsUpdatingDocument: (state, action) => {
            state.isUpdatingDocument = action.payload;
        },
        setUpdateDocumentError: (state, action) => {
            state.updateDocumentError = action.payload;
        },
        setIsDeletingDocument: (state, action) => {
            state.isDeletingDocument = action.payload;
        },
        setDeleteDocumentError: (state, action) => {
            state.deleteDocumentError = action.payload;
        },
        //
        setReminderConfig: (state, action) => {
            state.reminderConfig = action.payload;
        },
        setIsUpdatingReminderConfig: (state, action) => {
            state.isUpdatingReminderConfig = action.payload;
        },
        setIsFetchingReminderConfig: (state, action) => {
            state.isFetchingReminderConfig = action.payload;
        },
        setUpdateReminderConfigError: (state, action) => {
            state.updateReminderConfigError = action.payload;
        },
        setIsReminderSaving: (state, action) => {
            state.isReminderSaving = action.payload;
        },
        setReminderSavingError: (state, action) => {
            state.reminderSavingError = action.payload;
        },
        //
        setIsUpdatingSettings: (state, action) => {
            state.isUpdatingSettings = action.payload;
        },
        setUpdateSettingsError: (state, action) => {
            state.updateSettingsError = action.payload;
        },
        setAuditEvents: (state, action) => {
            state.auditEvents = {
                ...state.auditEvents,
                ...action.payload
            };
        },
        setIsAuditEventsFetching: (state, action) => {
            state.isAuditEventsFetching = action.payload;
        },
        setRolesForLoadingPlaceholders: (state, action) => {
            state.rolesForLoadingPlaceholders = action.payload;
        },
        setIsSenderVisibleForLoadingPlaceholders: (state, action) => {
            state.isSenderVisibleForLoadingPlaceholders = action.payload;
        }
    }
});
export const {
    setTemplate,
    setIsTemplateFetching,
    setTemplateError,
    setTemplateResponseStatusCode,
    setIsCreatingTemplate,
    setCreatingTemplateError,
    setUploadErrors,
    setRoles,
    // documents
    setDocuments,
    setIsDocumentsFetching,
    setDocumentsFetchError,
    setIsUploadingDocuments,
    setUploadDocumentsError,
    setIsUpdatingDocuments,
    setUpdateDocumentsError,
    setIsUpdatingDocument,
    setUpdateDocumentError,
    setIsDeletingDocument,
    setDeleteDocumentError,
    // reminder
    setReminderConfig,
    setIsUpdatingReminderConfig,
    setUpdateReminderConfigError,
    setIsReminderSaving,
    setReminderSavingError,
    //
    setIsUpdatingSettings,
    setUpdateSettingsError,
    setIsFetchingReminderConfig,
    setAuditEvents,
    setIsAuditEventsFetching,
    setRolesForLoadingPlaceholders,
    setIsSenderVisibleForLoadingPlaceholders
} = templateSlice.actions;

export const getIsTemplateInProgress = () => (dispatch, getState) => {
    const {
        isUpdatingReminderConfig,
        isTemplateFetching,
        isCreatingTemplate,
        isDocumentsFetching,
        isUploadingDocuments,
        isUpdatingDocuments,
        isUpdatingDocument,
        isDeletingDocument,
        isUpdatingSettings
    } = getState().template;

    const isInProgress = isUpdatingReminderConfig
        || isTemplateFetching
        || isCreatingTemplate
        || isDocumentsFetching
        || isUploadingDocuments
        || isUpdatingDocuments
        || isUpdatingDocument
        || isDeletingDocument
        || isUpdatingSettings;
    return isInProgress;
};

export const resetTemplateErrors = () => async (dispatch) => {
    dispatch(setTemplateError(null));
    dispatch(setTemplateResponseStatusCode(null));
    dispatch(setDocumentsFetchError(null));
    dispatch(setUploadDocumentsError(null));
    dispatch(setUpdateDocumentsError(null));
    dispatch(setUpdateDocumentError(null));
    dispatch(setDeleteDocumentError(null));
    dispatch(setUpdateSettingsError(null));
    dispatch(setUpdateReminderConfigError(null));
};

export const fetchTemplate = ({
    headers, baseUrl, params
} = {}) => async (dispatch) => {
    const {packageId} = params;
    const servicePath = `packages/${packageId}`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsTemplateFetching(true));
    dispatch(setTemplate({}));
    dispatch(setTemplateError(null));
    dispatch(setTemplateResponseStatusCode(null));

    try {
        const result = await httpClient.get(url, options);
        dispatch(setTemplate(result.data));
        dispatch(setIsTemplateFetching(false));
        dispatch(setTemplateError(null));
        dispatch(setTemplateResponseStatusCode(get(result, 'status')));

        return result.data;
    } catch (err) {
        dispatch(setIsTemplateFetching(false));
        dispatch(setTemplateError(getErrorMessage(err)));
        dispatch(setTemplateResponseStatusCode(getErrorCode(err)));

        return false;
    }
};
export const createTemplate = ({
    templatePackage, template, documents, bulkCSV
}) => async (dispatch, getState) => {
    const {app, user, dashboard} = getState();
    const isTemplateBulkSendable = get(template, 'bulkSendable', false);
    const defaultData = {
        autocomplete: true,
        data: {senderVisible: false, origin: 'validsign'},
        description: '',
        due: isTemplateBulkSendable ? '' : null,
        emailMessage: '',
        language: app.locale,
        name: '',
        notarized: false,
        settings: {ceremony: dashboard.packageSettings},
        timezoneId: user.user.timezoneId,
        type: PACKAGE_TYPES.TEMPLATE
    };

    dispatch(setIsCreatingTemplate(true));
    dispatch(setCreatingTemplateError(null));

    try {
        let templateId = null;

        templateId = await createPackageRequest({
            defaultData,
            template,
            packageData: templatePackage,
            documents,
            packageSettings: dashboard.packageSettings,
            onDocumentsUpload: (errors) => {
                dispatch(setUploadErrors(errors));
            }
        });

        dispatch(setIsCreatingTemplate(false));
        dispatch(setCreatingTemplateError(null));
        return templateId;
    } catch (err) {
        dispatch(setIsCreatingTemplate(false));

        if (!isTemplateBulkSendable) {
            dispatch(setCreatingTemplateError(getErrorMessage(err)));
        } else {
            try {
                const error = JSON.parse(err.data.replace(/<pre>|<\/pre>/g, ''));
                dispatch(setCreatingTemplateError(error));
            } catch (e) {
                dispatch(setCreatingTemplateError(getErrorMessage(err)));
            }
        }

        return false;
    }
};
export const updateTemplate = (payload) => async (dispatch, getState) => {
    const packageData = get(getState(), TEMPLATE_STORE_PATH);

    try {
        await updatePackage(packageData.id, payload);
        await dispatch(setTemplate({
            ...packageData,
            ...payload
        }));
        return true;
    } catch (err) {
        return false;
    }
};
// documents

export const fetchDocuments = () => async (dispatch, getState) => {
    const packageData = get(getState(), TEMPLATE_STORE_PATH);
    const servicePath = `packages/${packageData.id}`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    if (!packageData.id) {
        return false;
    }

    dispatch(setIsDocumentsFetching(true));
    dispatch(setDocumentsFetchError(null));

    try {
        const result = await httpClient.get(url);
        const documents = get(result, 'data.documents', []);
        dispatch(setDocuments(documents));
        dispatch(setIsDocumentsFetching(false));
        dispatch(setDocumentsFetchError(null));
        dispatch(deleteFetchDocumentEque());

        return true;
    } catch (err) {
        dispatch(setIsDocumentsFetching(false));
        dispatch(setDocumentsFetchError(getErrorMessage(err)));
        return false;
    }
};
export const fetchDocument = (packageId, documentId) => async (dispatch, getState) => {
    const template = get(getState(), 'template.template');
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}`;

    try {
        const result = await httpClient.get(url);
        dispatch(setTemplate({
            ...template,
            documents: template.documents.map((document) => {
                if (document.id === documentId) {
                    return result.data;
                }
                return document;
            })
        }));
        return true;
    } catch (err) {
        return false;
    }
};

export const fetchRoles = () => async (dispatch, getState) => {
    const packageData = get(getState(), TEMPLATE_STORE_PATH);
    const servicePath = `packages/${packageData.id}`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    if (!packageData.id) {
        return false;
    }

    try {
        const result = await httpClient.get(url);
        const roles = get(result, 'data.roles', []);
        dispatch(setRoles(roles));
        return roles;
    } catch (err) {
        return false;
    }
};

export const handleFetchTemplate = () => async (dispatch, getState) => {
    const packageId = get(getState(), 'template.template.id');
    const servicePath = `packages/${packageId}`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;
    if (!packageId) {
        return false;
    }

    try {
        const result = await httpClient.get(url);
        const documents = get(result, 'data.documents', []);
        const roles = get(result, 'data.roles', []);
        dispatch(setDocuments(documents));
        dispatch(setRoles(roles));
        return {roles, documents};
    } catch (err) {
        dispatch(setTemplateError(getErrorMessage(err)));
        return false;
    }
};

export const uploadDocuments = ({
    packageId, documents
}) => async (dispatch) => {
    const chunks = getDocumentsFormDataChunks(documents);

    dispatch(setIsUploadingDocuments(true));
    dispatch(setUploadDocumentsError(null));

    try {
        const result = await uploadDocumentChunks(packageId, chunks);
        const uploadedDocuments = result.results.map(({data}) => data);
        const failedDocuments = result.errors;
        const url = `${BASE_PATH}/proxy/packages/${packageId}`;
        const packageData = await httpClient.get(url);
        const updatedDataAttr = {
            wasApprovalsFromFileSettingSetByPreferences: false
        };

        await savePackageDataAttr({packageData: packageData.data, data: updatedDataAttr});

        if (!isEmpty(failedDocuments)) {
            dispatch(setUploadDocumentsError(failedDocuments.map(getErrorMessage)));
        }

        dispatch(setTemplate({
            ...packageData.data,
            data: {
                ...packageData.data?.data,
                ...updatedDataAttr
            }
        }));
        dispatch(setIsUploadingDocuments(false));
        return uploadedDocuments;
    } catch (err) {
        dispatch(setIsUploadingDocuments(false));
        dispatch(setUploadDocumentsError(getErrorMessage(err)));
        return null;
    }
};

export const updateDocuments = ({
    packageId, documents
}) => async (dispatch) => {
    dispatch(setIsUpdatingDocuments(true));
    dispatch(setUpdateDocumentsError(null));

    try {
        await updatePackageDocuments(packageId, documents);
        await dispatch(fetchDocuments());
        dispatch(setIsUpdatingDocuments(false));
        return true;
    } catch (err) {
        dispatch(setUpdateDocumentsError(getErrorMessage(err)));
        dispatch(setIsUpdatingDocuments(false));
        return false;
    }
};

export const updateDocument = ({
    packageId, document
}) => async (dispatch, getState) => {
    dispatch(setIsUpdatingDocument(true));
    dispatch(setUpdateDocumentError(null));

    try {
        await updatePackageDocument(packageId, document.id, document);
        await dispatch(fetchDocuments());
        dispatch(setIsUpdatingDocument(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingDocument(false));
        dispatch(setUpdateDocumentError('esl.feedback.document_reorder.error'));
        return false;
    }
};

export const deleteDocument = ({
    packageId, documentId, callback
}) => async (dispatch, getState) => {
    const packageData = get(getState(), TEMPLATE_STORE_PATH);

    dispatch(setIsDeletingDocument(true));
    dispatch(setDeleteDocumentError(null));

    try {
        await deletePackageDocument(packageId, documentId);
        dispatch(setTemplate({
            ...packageData,
            documents: packageData.documents.filter(({id}) => id !== documentId)
        }));
        dispatch(setIsDeletingDocument(false));
        if (callback) {
            callback();
        }
        return true;
    } catch (err) {
        dispatch(setIsDeletingDocument(false));

        dispatch(setDeleteDocumentError(getErrorMessage(err)));
        return false;
    }
};

//

export const fetchReminderConfig = ({
    headers, baseUrl, params
} = {}) => async (dispatch) => {
    const {packageId} = params;
    const servicePath = `packages/${packageId}/reminders`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }
    dispatch(setIsFetchingReminderConfig(true));
    try {
        const result = await httpClient.get(url, options);
        const updatedData = isEmpty(result.data) ? {} : mapRemindersFromCamelToSnakeCase(result.data);
        dispatch(setReminderConfig(updatedData));
        dispatch(setIsFetchingReminderConfig(false));
        return updatedData;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsFetchingReminderConfig(false));

        return false;
    }
};

export const saveReminderConfig = (packageId, payload) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/reminders`;

    dispatch(setIsReminderSaving(true));
    dispatch(setReminderSavingError(null));

    const updatedReminders = mapRemindersFromSnakeToCamelCase(payload);
    try {
        await httpClient.post(url, {
            packageId,
            ...updatedReminders
        });

        dispatch(setIsReminderSaving(false));
        dispatch(setReminderSavingError(null));
        return true;
    } catch (err) {
        dispatch(setIsReminderSaving(false));
        dispatch(setReminderSavingError(getErrorMessage(err)));
        return false;
    }
};

export const createReminderConfig = (payload) => async (dispatch, getState) => {
    const transaction = get(getState(), TEMPLATE_STORE_PATH);
    dispatch(setIsUpdatingReminderConfig(true));
    dispatch(setUpdateReminderConfigError(null));
    const updatedReminder = mapRemindersFromSnakeToCamelCase(payload);

    try {
        await httpClient.post(`${BASE_PATH}/proxy/packages/${transaction.id}/reminders`, {
            ...updatedReminder,
            reminders: [],
            packageId: transaction.id
        });
        await dispatch(fetchReminderConfig({
            params: {
                packageId: transaction.id
            }
        }));
        dispatch(setIsUpdatingReminderConfig(false));

        return true;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsUpdatingReminderConfig(false));

        return false;
    }
};

export const updateReminderConfig = (payload) => async (dispatch, getState) => {
    const transaction = get(getState(), TEMPLATE_STORE_PATH);
    dispatch(setIsUpdatingReminderConfig(true));
    dispatch(setUpdateReminderConfigError(null));
    const updatedReminder = mapRemindersFromSnakeToCamelCase(payload);

    try {
        await httpClient.put(`${BASE_PATH}/proxy/packages/${transaction.id}/reminders`, {
            ...updatedReminder,
            reminders: [],
            packageId: transaction.id
        });
        await dispatch(fetchReminderConfig({
            params: {
                packageId: transaction.id
            }
        }));
        dispatch(setIsUpdatingReminderConfig(false));

        return true;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsUpdatingReminderConfig(false));

        return false;
    }
};

export const deleteReminderConfig = () => async (dispatch, getState) => {
    const transaction = get(getState(), TEMPLATE_STORE_PATH);
    dispatch(setIsUpdatingReminderConfig(true));
    dispatch(setUpdateReminderConfigError(null));

    try {
        await httpClient.delete(`${BASE_PATH}/proxy/packages/${transaction.id}/reminders`);
        await dispatch(fetchReminderConfig({
            params: {
                packageId: transaction.id
            }
        }));
        dispatch(setIsUpdatingReminderConfig(false));
        return true;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsUpdatingReminderConfig(false));

        return false;
    }
};
// settings
export const updateSettings = (payload) => async (dispatch, getState) => {
    const packageData = get(getState(), TEMPLATE_STORE_PATH);

    dispatch(setIsUpdatingSettings(true));
    dispatch(setUpdateSettingsError(null));

    try {
        await updatePackage(packageData.id, payload);
        await dispatch(setTemplate({
            ...packageData,
            ...payload
        }));
        dispatch(setIsUpdatingSettings(false));

        return true;
    } catch (err) {
        dispatch(setUpdateSettingsError(getErrorMessage(err)));
        dispatch(setIsUpdatingSettings(false));

        return false;
    }
};

export const fetchAudit = (packageId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/audit`;
    dispatch(setIsAuditEventsFetching(true));

    try {
        const result = await httpClient.get(url);
        const auditEvents = get(result, 'data["audit-events"]', []);

        await dispatch(setAuditEvents({
            [packageId]: auditEvents
        }));
        dispatch(setIsAuditEventsFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsAuditEventsFetching(false));
        return false;
    }
};

export const selectTemplate = (state) => state.template.template;
export const selectRoles = (state) => state.template.template.roles || [];
export const selectIsTemplateFetching = (state) => state.template.isTemplateFetching;
export const selectTemplateError = (state) => state.template.templateError;
export const selectTemplateResponseStatusCode = (state) => state.template.templateResponseStatusCode;
export const selectReminderConfig = (state) => state.template.reminderConfig;
export const selectIsReminderSaving = (state) => state.template.isReminderSaving;
export const selectIsCreatingTemplate = (state) => state.template.isCreatingTemplate;
export const selectCreatingTemplateError = (state) => state.template.creatingTemplateError;
export const selectIsUpdatingReminderConfig = (state) => state.template.isUpdatingReminderConfig;
export const selectUpdateReminderConfigError = (state) => state.template.updateReminderConfigError;
export const selectIsFetchingReminderConfig = (state) => state.template.isFetchingReminderConfig;
export const selectIsUpdatingSettings = (state) => state.template.isUpdatingSettings;
export const selectUpdateSettingsError = (state) => state.template.updateSettingsError;
export const selectUpdateDocumentError = (state) => state.template.updateDocumentError;
export const selectIsUploadingDocuments = (state) => state.template.isUploadingDocuments;
export const selectIsUpdatingDocuments = (state) => state.template.isUpdatingDocuments;
export const selectIsUpdatingDocument = (state) => state.template.isUpdatingDocument;
export const selectAuditEvents = (state) => state.template.auditEvents;
export const selectRolesForLoadingPlaceholders = (state) => state.template.rolesForLoadingPlaceholders;
export const selectIsSenderVisibleForLoadingPlaceholders = (state) => state.transaction.isSenderVisibleForLoadingPlaceholders;

export default templateSlice.reducer;
