import axios from 'axios';
import isClient from './isClient';
import {CSRF_TOKEN_META_TAG_NAME} from '../../constants';

const ESL_API_VERSION = '11.47';
const CSRF_HEADER_NAME = 'X-CSRF-Token';
const TIMEOUT_MS = 10000; // 10 seconds

let instance;

axios.defaults.withCredentials = true;

function getHttpClient() {
    if (!instance) {
        instance = axios.create({
            headers: {
                Accept: `application/json; esl-api-version=${ESL_API_VERSION}`
            },
            timeout: TIMEOUT_MS
        });

        if (isClient) {
            instance.interceptors.request.use(async (config) => {
                const csrfTokenMeta = window.document.querySelector(`meta[name="${CSRF_TOKEN_META_TAG_NAME}"]`);
                const token = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') : null;
                const modifiedConfig = {...config};

                if (token) {
                    modifiedConfig.headers[CSRF_HEADER_NAME] = token;
                }

                return modifiedConfig;
            });
        }
    }

    return instance;
}

export default getHttpClient();
