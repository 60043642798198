export default {
    sender_admin: {
        heading: 'sender_admin',
        event_notification: 'sender_admin.event_notification',
        custom_fields: 'sender_admin.custom_fields',
        subscription: 'sender_admin.subscription',
        reports: 'sender_admin.reports',
        users: 'sender_admin.users',
        role: 'sender_admin.role',
        security_settings: 'sender_admin.security_settings',
        customization: 'sender_admin.customization',
        notary: 'sender_admin.notary',
        api_access: 'sender_admin.api_access',
        data_management: 'sender_admin.data_management',
        sub_account_management: 'sender_admin.sub_account_management',
        account_configuration: 'sender_admin.self_serve_account_settings'
    },
    groups: {
        heading: 'groups',
        group_signing_management: 'groups.group_signing_management'
    },
    templates_layouts: {
        heading: 'templates_layouts',
        share_templates: 'templates_layouts.share_templates',
        share_layouts: 'templates_layouts.share_layouts'
    },
    transaction: {
        heading: 'transaction',
        transaction: 'transaction.transaction',
        in_person: 'transaction.in_person',
        change_signer: 'transaction.change_signer',
        delegation_visibility: 'transaction.delegation_visibility',
        access_other_users_transactions: 'transaction.access_other_users_transactions'
    }
};
