import {useMutation} from '@apollo/client';
import {FILE_UPLOAD_TIMEOUT_MS} from '@constants/main';
import UPLOAD_ATTACHMENT from '../../../../gql/uploadAttachment.gql';

export default function useUploadAttachment({options} = {}) {
    const [uploadAttachment, {
        loading, error, data, reset
    }] = useMutation(UPLOAD_ATTACHMENT, {
        ...options,
        timeout: FILE_UPLOAD_TIMEOUT_MS
    });

    return {
        uploadAttachment, loading, error, data, reset
    };
}
