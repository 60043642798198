import {useDispatch} from 'react-redux';
import {firstPendingDocumentId} from '../utils/selectors';
import {useDocumentsWithPages} from './queries';
import {setSelectedDocumentId} from '../signerUiSlice';

export const useFindAndSetFirstPendingDocumentAsActive = () => {
    const dispatch = useDispatch();
    const {refetch} = useDocumentsWithPages();

    async function findAndSetFirstPendingDocumentAsActive() {
        const {data} = await refetch();
        const documents = data?.ceremony?.transaction?.documents;
        const firstPendingDocument = firstPendingDocumentId(documents) || documents[0].id;

        dispatch(setSelectedDocumentId(firstPendingDocument || documents[0].id));
    }

    return {findAndSetFirstPendingDocumentAsActive};
};
