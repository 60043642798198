/* eslint-disable no-unused-vars */
import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import httpClient from '../../utils/httpClient';
// eslint-disable-next-line import/no-cycle
import {deleteFetchDocumentEque} from '../Designer/designerSlice';
import {BASE_PATH, TRANSACTION_STATUSES} from '../../../constants';
import {
    updatePackage,
    updatePackageDocuments,
    updatePackageDocument,
    deletePackageDocument,
    deletePackagePermanently,
    restorePackage,
    archivePackage,
    unArchivePackage,
    uploadDocumentChunks,
    savePackageDataAttr
} from '../../utils/requests/packages';
import {fetchAvatarsByEmails} from '../Packages/Recipients/recipientsSlice';
import {getRecipientsEmailsFromPackage} from '../../utils/helpers';
import {getErrorCode, getErrorMessage} from '../../utils/requests/error';
import getDocumentsFormDataChunks from '../../utils/getDocumentsFormDataChunks';
import {mapRemindersFromCamelToSnakeCase, mapRemindersFromSnakeToCamelCase} from '../Packages/PackageSettings/Reminders/utils';

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        transaction: {},
        reminderConfig: {},
        remindersInfo: {},
        visibility: {},
        auditEvents: {},
        unlockRoleInfo: {},

        isUpdatingReminderConfig: false,
        updateReminderConfigError: null,
        isFetchingReminderConfig: false,
        isTransactionFetching: false,
        transactionError: null,
        isDocumentsFetching: false,
        documentsFetchError: null,
        isUploadingDocuments: false,
        uploadDocumentsError: {},
        isUpdatingDocuments: false,
        updateDocumentsError: null,
        isUpdatingDocument: false,
        updateDocumentError: null,
        isDeletingDocument: false,
        deleteDocumentError: null,
        isUpdatingSettings: false,
        updateSettingsError: null,
        isAuditEventsFetching: false,
        isUnlockRoleFetching: false,
        unlockRoleError: null,
        isUpdatingTransaction: false,
        updateTransactionError: null,
        rolesForLoadingPlaceholders: null,
        isSenderVisibleForLoadingPlaceholders: null
    },
    reducers: {
        setTransaction: (state, action) => {
            state.transaction = action.payload;
        },
        setDocuments: (state, action) => {
            state.transaction.documents = action.payload;
        },
        setRoles: (state, action) => {
            state.transaction.roles = action.payload;
        },
        setReminderConfig: (state, action) => {
            state.reminderConfig = action.payload;
        },
        setRemindersInfo: (state, action) => {
            const {roleId} = action.payload;
            const roleState = get(state, `remindersInfo[${roleId}]`, {});

            state.remindersInfo = {
                ...state.remindersInfo,
                [roleId]: {
                    ...roleState,
                    ...action.payload
                }
            };
        },
        setUnlockRoleInfo: (state, action) => {
            const {roleId} = action.payload;
            const roleState = get(state, `unlockRoleInfo[${roleId}]`, {});

            state.unlockRoleInfo = {
                ...state.unlockRoleInfo,
                [roleId]: {
                    ...roleState,
                    ...action.payload
                }
            };
        },

        setVisibility: (state, action) => {
            state.visibility = action.payload;
        },
        setAuditEvents: (state, action) => {
            state.auditEvents = {
                ...state.auditEvents,
                ...action.payload
            };
        },
        setIsTransactionFetching: (state, action) => {
            state.isTransactionFetching = action.payload;
        },
        setIsDocumentsFetching: (state, action) => {
            state.isDocumentsFetching = action.payload;
        },
        setDocumentsFetchError: (state, action) => {
            state.documentsFetchError = action.payload;
        },
        setTransactionError: (state, action) => {
            state.transactionError = action.payload;
        },
        setTransactionResponseStatusCode: (state, action) => {
            state.transactionResponseStatusCode = action.payload;
        },
        setIsUploadingDocuments: (state, action) => {
            state.isUploadingDocuments = action.payload;
        },
        setUploadDocumentsError: (state, action) => {
            state.uploadDocumentsError = action.payload;
        },
        setIsUpdatingDocuments: (state, action) => {
            state.isUpdatingDocuments = action.payload;
        },
        setUpdateDocumentsError: (state, action) => {
            state.updateDocumentsError = action.payload;
        },
        setIsUpdatingDocument: (state, action) => {
            state.isUpdatingDocument = action.payload;
        },
        setUpdateDocumentError: (state, action) => {
            state.updateDocumentError = action.payload;
        },
        setIsDeletingDocument: (state, action) => {
            state.isDeletingDocument = action.payload;
        },
        setDeleteDocumentError: (state, action) => {
            state.deleteDocumentError = action.payload;
        },
        setIsUpdatingReminderConfig: (state, action) => {
            state.isUpdatingReminderConfig = action.payload;
        },
        setUpdateReminderConfigError: (state, action) => {
            state.updateReminderConfigError = action.payload;
        },
        //
        setIsReminderSaving: (state, action) => {
            state.isReminderSaving = action.payload;
        },
        setReminderSavingError: (state, action) => {
            state.reminderSavingError = action.payload;
        },
        setIsFetchingReminderConfig: (state, action) => {
            state.isFetchingReminderConfig = action.payload;
        },
        setIsUpdatingSettings: (state, action) => {
            state.isUpdatingSettings = action.payload;
        },
        setUpdateSettingsError: (state, action) => {
            state.updateSettingsError = action.payload;
        },
        setIsUpdatingTransaction: (state, action) => {
            state.isUpdatingTransaction = action.payload;
        },
        setUpdateTransactionError: (state, action) => {
            state.updateTransactionError = action.payload;
        },
        setIsVisibilityFetching: (state, action) => {
            state.isVisibilityFetching = action.payload;
        },
        setFetchingVisibilityError: (state, action) => {
            state.fetchingVisibilityError = action.payload;
        },
        setIsAuditEventsFetching: (state, action) => {
            state.isAuditEventsFetching = action.payload;
        },
        setRolesForLoadingPlaceholders: (state, action) => {
            state.rolesForLoadingPlaceholders = action.payload;
        },
        setIsSenderVisibleForLoadingPlaceholders: (state, action) => {
            state.isSenderVisibleForLoadingPlaceholders = action.payload;
        }
    }
});

export const {
    setTransaction,
    setDocuments,
    setRoles,
    setReminderConfig,
    setRemindersInfo,
    setIsReminderSaving,
    setReminderSavingError,
    setIsFetchingReminderConfig,

    setVisibility,
    setAuditEvents,

    setIsTransactionFetching,
    setTransactionError,
    setTransactionResponseStatusCode,
    setIsDocumentsFetching,
    setDocumentsFetchError,
    setIsUploadingDocuments,
    setUploadDocumentsError,
    setIsUpdatingDocuments,
    setUpdateDocumentsError,
    setIsUpdatingDocument,
    setUpdateDocumentError,
    setIsDeletingDocument,
    setDeleteDocumentError,
    setIsUpdatingReminderConfig,
    setUpdateReminderConfigError,
    setIsUpdatingSettings,
    setUpdateSettingsError,
    setIsUpdatingTransaction,
    setUpdateTransactionError,
    setIsAuditEventsFetching,
    setIsVisibilityFetching,
    setFetchingVisibilityError,
    setUnlockRoleInfo,
    setIsSenderVisibleForLoadingPlaceholders,
    setRolesForLoadingPlaceholders
} = transactionSlice.actions;

export const resetTransactionErrors = () => async (dispatch) => {
    dispatch(setTransactionError(null));
    dispatch(setTransactionResponseStatusCode(null));
    dispatch(setDocumentsFetchError(null));
    dispatch(setUploadDocumentsError(null));
    dispatch(setUpdateDocumentsError(null));
    dispatch(setUpdateDocumentError(null));
    dispatch(setDeleteDocumentError(null));
    dispatch(setUpdateSettingsError(null));
    dispatch(setUpdateReminderConfigError(null));
};
export const fetchTransaction = ({headers, baseUrl, params} = {}) => async (dispatch) => {
    const {packageId, skipSetEmptyTransaction, skipAvatarsFetch} = params;
    const servicePath = `packages/${packageId}`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }

    dispatch(setIsTransactionFetching(true));
    if (!skipSetEmptyTransaction) {
        dispatch(setTransaction({}));
    }
    dispatch(setTransactionError(null));
    dispatch(setTransactionResponseStatusCode(null));

    try {
        const result = await httpClient.get(url, options);
        const recipientEmails = getRecipientsEmailsFromPackage([result.data]);
        if (!skipAvatarsFetch) {
            await dispatch(fetchAvatarsByEmails({baseUrl, headers, emails: recipientEmails}));
        }
        dispatch(setTransaction(result.data));
        dispatch(setIsTransactionFetching(false));
        dispatch(setTransactionResponseStatusCode(get(result, 'status')));
        return result.data;
    } catch (err) {
        dispatch(setIsTransactionFetching(false));
        dispatch(setTransactionError(getErrorMessage(err)));
        dispatch(setTransactionResponseStatusCode(getErrorCode(err)));
        return false;
    }
};

export const updateTransaction = (payload) => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');
    try {
        await updatePackage(transaction.id, payload);
        await dispatch(setTransaction({
            ...transaction,
            ...payload
        }));
        return true;
    } catch (err) {
        return false;
    }
};

export const fetchDocuments = () => async (dispatch, getState) => {
    const transactionId = get(getState(), 'transaction.transaction.id');
    const servicePath = `packages/${transactionId}`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    if (!transactionId) {
        return false;
    }
    dispatch(setIsDocumentsFetching(true));
    dispatch(setDocumentsFetchError(null));

    try {
        const result = await httpClient.get(url);
        const documents = get(result, 'data.documents', []);
        dispatch(setDocuments(documents));
        dispatch(setIsDocumentsFetching(false));
        dispatch(setDocumentsFetchError(null));
        await dispatch(deleteFetchDocumentEque());
        return documents;
    } catch (err) {
        dispatch(setIsDocumentsFetching(false));
        dispatch(setDocumentsFetchError(getErrorMessage(err)));
        return false;
    }
};

export const fetchRoles = () => async (dispatch, getState) => {
    const transactionId = get(getState(), 'transaction.transaction.id');
    const servicePath = `packages/${transactionId}`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;

    if (!transactionId) {
        return false;
    }

    try {
        const result = await httpClient.get(url);
        const roles = get(result, 'data.roles', []);
        dispatch(setRoles(roles));
        return roles;
    } catch (err) {
        return false;
    }
};

export const handleFetchTransaction = () => async (dispatch, getState) => {
    const packageId = get(getState(), 'transaction.transaction.id');
    const servicePath = `packages/${packageId}`;
    const url = `${BASE_PATH}/proxy/${servicePath}`;
    if (!packageId) {
        return false;
    }

    try {
        const result = await httpClient.get(url);
        const resultTransaction = get(result, 'data', {});
        const documents = get(resultTransaction, 'documents', []);
        const roles = get(resultTransaction, 'roles', []);
        const recipientEmails = getRecipientsEmailsFromPackage([resultTransaction]);

        dispatch(setDocuments(documents));
        dispatch(setRoles(roles));
        await dispatch(fetchAvatarsByEmails({emails: recipientEmails}));
        return {roles, documents};
    } catch (err) {
        dispatch(setTransactionError(getErrorMessage(err)));
        return false;
    }
};

export const uploadDocuments = ({
    packageId, documents
}) => async (dispatch) => {
    const chunks = getDocumentsFormDataChunks(documents);

    dispatch(setIsUploadingDocuments(true));
    dispatch(setUploadDocumentsError(null));

    try {
        const result = await uploadDocumentChunks(packageId, chunks);
        const uploadedDocuments = result.results.map(({data}) => data);
        const failedDocuments = result.errors;
        const url = `${BASE_PATH}/proxy/packages/${packageId}`;
        const packageData = await httpClient.get(url);
        const updatedDataAttr = {
            wasApprovalsFromFileSettingSetByPreferences: false
        };

        await savePackageDataAttr({packageData: packageData.data, data: updatedDataAttr});

        if (!isEmpty(failedDocuments)) {
            dispatch(setUploadDocumentsError(failedDocuments.map(getErrorMessage)));
        }
        dispatch(setTransaction({
            ...packageData.data,
            data: {
                ...packageData.data?.data,
                ...updatedDataAttr
            }
        }));
        dispatch(setIsUploadingDocuments(false));
        return !isEmpty(uploadedDocuments) ? uploadedDocuments : null;
    } catch (err) {
        dispatch(setIsUploadingDocuments(false));
        dispatch(setUploadDocumentsError(getErrorMessage(err)));
        return null;
    }
};

export const updateDocuments = ({
    packageId, documents
}) => async (dispatch) => {
    dispatch(setIsUpdatingDocuments(true));
    dispatch(setUpdateDocumentsError(null));

    try {
        await updatePackageDocuments(packageId, documents);
        await dispatch(fetchDocuments());
        dispatch(setIsUpdatingDocuments(false));
        return true;
    } catch (err) {
        dispatch(setUpdateDocumentsError('esl.feedback.document_reorder.error'));
        dispatch(setIsUpdatingDocuments(false));
        return false;
    }
};

export const updateDocument = ({
    packageId, document
}) => async (dispatch, getState) => {
    dispatch(setIsUpdatingDocument(true));
    dispatch(setUpdateDocumentError(null));

    try {
        await updatePackageDocument(packageId, document.id, document);
        await dispatch(fetchDocuments());
        dispatch(setIsUpdatingDocument(false));
        return true;
    } catch (err) {
        dispatch(setIsUpdatingDocument(false));
        dispatch(setUpdateDocumentError('esl.feedback.document_reorder.error'));
        return false;
    }
};

export const deleteDocument = ({
    packageId, documentId, callback
}) => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');

    dispatch(setIsDeletingDocument(true));
    dispatch(setDeleteDocumentError(null));

    try {
        await deletePackageDocument(packageId, documentId);
        dispatch(setTransaction({
            ...transaction,
            documents: transaction.documents.filter(({id}) => id !== documentId)
        }));
        dispatch(setIsDeletingDocument(false));
        if (callback) {
            callback();
        }
        return true;
    } catch (err) {
        dispatch(setIsDeletingDocument(false));
        dispatch(setDeleteDocumentError('esl.feedback.document_reorder.error'));
        return false;
    }
};

export const updateSettings = (payload) => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');

    dispatch(setIsUpdatingSettings(true));
    dispatch(setUpdateSettingsError(null));

    try {
        await updatePackage(transaction.id, payload);
        await dispatch(setTransaction({
            ...transaction,
            ...payload
        }));
        dispatch(setIsUpdatingSettings(false));

        return true;
    } catch (err) {
        dispatch(setUpdateSettingsError(err));
        dispatch(setIsUpdatingSettings(false));

        return false;
    }
};

export const fetchReminderConfig = ({
    headers, baseUrl, params
} = {}) => async (dispatch) => {
    const {packageId} = params;
    const servicePath = `packages/${packageId}/reminders`;
    const url = baseUrl ? `${baseUrl}/api/${servicePath}` : `${BASE_PATH}/proxy/${servicePath}`;
    const options = {};
    if (headers) {
        options.headers = headers;
    }
    dispatch(setIsFetchingReminderConfig(true));
    try {
        const result = await httpClient.get(url, options);
        const updatedData = isEmpty(result.data) ? {} : mapRemindersFromCamelToSnakeCase(result.data);
        dispatch(setReminderConfig(updatedData));
        dispatch(setIsFetchingReminderConfig(false));
        return updatedData;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsFetchingReminderConfig(false));

        return false;
    }
};

export const saveReminderConfig = (packageId, payload) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/reminders`;

    dispatch(setIsReminderSaving(true));
    dispatch(setReminderSavingError(null));

    const updatedReminders = mapRemindersFromSnakeToCamelCase(payload);
    try {
        await httpClient.post(url, {
            packageId,
            ...updatedReminders
        });

        dispatch(setIsReminderSaving(false));
        dispatch(setReminderSavingError(null));
        return true;
    } catch (err) {
        dispatch(setIsReminderSaving(false));
        dispatch(setReminderSavingError(getErrorMessage(err)));
        return false;
    }
};

export const createReminderConfig = (payload) => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');
    dispatch(setIsUpdatingReminderConfig(true));
    dispatch(setUpdateReminderConfigError(null));
    const updatedReminder = mapRemindersFromSnakeToCamelCase(payload);

    try {
        await httpClient.post(`${BASE_PATH}/proxy/packages/${transaction.id}/reminders`, {
            ...updatedReminder,
            reminders: [],
            packageId: transaction.id
        });
        await dispatch(fetchReminderConfig({
            params: {
                packageId: transaction.id
            }
        }));
        dispatch(setIsUpdatingReminderConfig(false));

        return true;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsUpdatingReminderConfig(false));

        return false;
    }
};

export const updateReminderConfig = (payload) => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');
    dispatch(setIsUpdatingReminderConfig(true));
    dispatch(setUpdateReminderConfigError(null));
    const updatedReminder = mapRemindersFromSnakeToCamelCase(payload);

    try {
        await httpClient.put(`${BASE_PATH}/proxy/packages/${transaction.id}/reminders`, {
            ...updatedReminder,
            reminders: [],
            packageId: transaction.id
        });
        await dispatch(fetchReminderConfig({
            params: {
                packageId: transaction.id
            }
        }));
        dispatch(setIsUpdatingReminderConfig(false));

        return true;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsUpdatingReminderConfig(false));

        return false;
    }
};

export const deleteReminderConfig = () => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');
    dispatch(setIsUpdatingReminderConfig(true));
    dispatch(setUpdateReminderConfigError(null));

    try {
        await httpClient.delete(`${BASE_PATH}/proxy/packages/${transaction.id}/reminders`);
        await dispatch(fetchReminderConfig({
            params: {
                packageId: transaction.id
            }
        }));
        dispatch(setIsUpdatingReminderConfig(false));
        return true;
    } catch (err) {
        dispatch(setUpdateReminderConfigError(getErrorMessage(err)));
        dispatch(setIsUpdatingReminderConfig(false));

        return false;
    }
};

export const sendReminder = (packageId, roleId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/roles/${roleId}/notifications`;

    dispatch(setRemindersInfo({
        roleId,
        wasSuccessful: false,
        isSending: true,
        error: null
    }));

    try {
        const wasSuccessful = await httpClient.post(url);
        dispatch(setRemindersInfo({
            roleId,
            wasSuccessful: !!wasSuccessful,
            isSending: false,
            error: null
        }));

        return true;
    } catch (err) {
        dispatch(setRemindersInfo({
            roleId,
            wasSuccessful: false,
            isSending: false,
            error: getErrorMessage(err)
        }));

        return false;
    }
};

const updateRole = (rolesArr, updatedRole) => rolesArr.map((role) => {
    if (role.id === updatedRole.id) {
        return updatedRole;
    }
    return role;
});

export const unlockRole = (packageId, roleId) => async (dispatch, getState) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/roles/${roleId}/unlock`;
    const packageData = get(getState(), 'transaction.transaction');
    dispatch(setUnlockRoleInfo({
        roleId,
        wasSuccessful: false,
        isFetching: true,
        error: null
    }));
    try {
        const res = await httpClient.post(url);
        dispatch(setRoles(updateRole(packageData.roles, res.data)));
        dispatch(setUnlockRoleInfo({
            roleId,
            wasSuccessful: true,
            isFetching: false,
            error: null
        }));

        return true;
    } catch (error) {
        dispatch(setUnlockRoleInfo({
            roleId,
            wasSuccessful: false,
            isFetching: false,
            error
        }));

        return false;
    }
};

export const fetchAudit = (packageId) => async (dispatch) => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/audit`;
    dispatch(setIsAuditEventsFetching(true));

    try {
        const result = await httpClient.get(url);
        const auditEvents = get(result, 'data["audit-events"]', []);

        await dispatch(setAuditEvents({
            [packageId]: auditEvents
        }));
        dispatch(setIsAuditEventsFetching(false));
        return true;
    } catch (err) {
        dispatch(setIsAuditEventsFetching(false));
        return false;
    }
};

export const fetchVisibility = (packageId) => async (dispatch, getState) => {
    const {templates} = getState();
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/visibility`;

    dispatch(setIsVisibilityFetching(true));
    dispatch(setFetchingVisibilityError(null));

    try {
        const result = await httpClient.get(url);
        const configurations = get(result, 'data.configurations', []);

        dispatch(setVisibility({
            ...templates.visibility,
            [packageId]: configurations
        }));
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(null));
        return true;
    } catch (err) {
        dispatch(setIsVisibilityFetching(false));
        dispatch(setFetchingVisibilityError(getErrorMessage(err)));
        return false;
    }
};

export const fetchDocument = (packageId, documentId) => async (dispatch, getState) => {
    const transaction = get(getState(), 'transaction.transaction');
    const url = `${BASE_PATH}/proxy/packages/${packageId}/documents/${documentId}`;

    try {
        const result = await httpClient.get(url);
        dispatch(setTransaction({
            ...transaction,
            documents: transaction.documents.map((document) => {
                if (document.id === documentId) {
                    return result.data;
                }
                return document;
            })
        }));
        return true;
    } catch (err) {
        return false;
    }
};

export const moveToDraft = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');

    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));

    try {
        await httpClient.put(`${BASE_PATH}/proxy/packages/${id}`, {
            status: TRANSACTION_STATUSES.DRAFT
        });
        await dispatch(fetchTransaction({
            params: {
                packageId: id
            }
        }));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));

        return false;
    }
};

export const moveToTrash = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');

    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));

    try {
        await httpClient.put(`${BASE_PATH}/proxy/packages/${id}`, {
            trashed: true
        });
        await dispatch(fetchTransaction({
            params: {
                packageId: id
            }
        }));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));
        return false;
    }
};
export const complete = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');

    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));

    try {
        await httpClient.put(`${BASE_PATH}/proxy/packages/${id}`, {
            status: TRANSACTION_STATUSES.COMPLETED
        });
        await dispatch(fetchTransaction({
            params: {
                packageId: id
            }
        }));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));

        return false;
    }
};
export const deletePermanently = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');
    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));
    try {
        await deletePackagePermanently(id);
        await dispatch(setTransaction([]));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));

        return false;
    }
};
export const restore = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');
    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));
    try {
        await restorePackage(id);
        await dispatch(fetchTransaction({
            params: {
                packageId: id
            }
        }));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));

        return false;
    }
};

export const archive = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');

    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));
    try {
        await archivePackage(id);
        await dispatch(fetchTransaction({
            params: {
                packageId: id
            }
        }));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));

        return false;
    }
};

export const unArchive = (transactionId) => async (dispatch, getState) => {
    const id = transactionId || get(getState(), 'transaction.transaction.id');

    dispatch(setIsUpdatingTransaction(true));
    dispatch(setUpdateTransactionError(null));

    try {
        await unArchivePackage(id);
        await dispatch(fetchTransaction({
            params: {
                packageId: id
            }
        }));
        dispatch(setIsUpdatingTransaction(false));

        return true;
    } catch (err) {
        dispatch(setUpdateTransactionError(getErrorMessage(err)));

        return false;
    }
};

export const fetchSigningUrl = (packageId, roleId) => async () => {
    const url = `${BASE_PATH}/proxy/packages/${packageId}/roles/${roleId}/signingUrl`;

    try {
        const result = await httpClient.get(url);
        return get(result, 'data', {});
    } catch (err) {
        return false;
    }
};

export const selectTransaction = (state) => state.transaction.transaction;
export const selectRoles = (state) => state.transaction.transaction.roles || [];
export const selectReminderConfig = (state) => state.transaction.reminderConfig;
export const selectRemindersInfo = (state) => state.transaction.remindersInfo;
export const selectIsFetchingReminderConfig = (state) => state.transaction.isFetchingReminderConfig;
export const selectIsReminderSaving = (state) => state.transactions.isReminderSaving;
export const selectVisibility = (state) => state.transaction.visibility;
export const selectAuditEvents = (state) => state.transaction.auditEvents;
export const selectUnlockRoleInfo = (state) => state.transaction.unlockRoleInfo;
export const selectIsTransactionFetching = (state) => state.transaction.isTransactionFetching;
export const selectIsDocumentsFetching = (state) => state.transaction.isDocumentsFetching;
export const selectIsUploadingDocuments = (state) => state.transaction.isUploadingDocuments;
export const selectIsUpdatingDocuments = (state) => state.transaction.isUpdatingDocuments;
export const selectIsDeletingDocument = (state) => state.transaction.isDeletingDocument;
export const selectIsUpdatingDocument = (state) => state.transaction.isUpdatingDocument;
export const selectIsUpdatingReminderConfig = (state) => state.transaction.isUpdatingReminderConfig;
export const selectIsUpdatingSettings = (state) => state.transaction.isUpdatingSettings;
export const selectTransactionError = (state) => state.transaction.transactionError;
export const selectTransactionResponceStatusCode = (state) => state.transaction.transactionResponseStatusCode;
export const selectDocumentsFetchError = (state) => state.transaction.documentsFetchError;
export const selectUploadDocumentsError = (state) => state.transaction.uploadDocumentsError;
export const selectUpdateDocumentsError = (state) => state.transaction.updateDocumentsError;
export const selectDeleteDocumentError = (state) => state.transaction.deleteDocumentError;
export const selectUpdateDocumentError = (state) => state.transaction.updateDocumentError;
export const selectUpdateReminderConfigError = (state) => state.transaction.updateReminderConfigError;
export const selectUpdateSettingsError = (state) => state.transaction.updateSettingsError;
export const selectUpdateTransactionError = (state) => state.transaction.updateTransactionError;
export const selectRolesForLoadingPlaceholders = (state) => state.transaction.rolesForLoadingPlaceholders;
export const selectIsSenderVisibleForLoadingPlaceholders = (state) => state.transaction.isSenderVisibleForLoadingPlaceholders;

export default transactionSlice.reducer;
