import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {NEW_DIRECT_SIGN_PATH} from '@ValidSignBV/constants';
import EmailForm from './EmailForm/EmailForm';
import PasswordForm from '../../shared/PasswordForm/PasswordForm';
import SsoForm from './SsoForm/SsoForm';
import Loading from '../../shared/Loading/Loading';
import Notifications from '../Notifications/Notifications';
import {
    authenticate,
    fetchSsoIdps,
    selectAuthError,
    selectEmailSsoIdpsFetchedFor,
    selectIsSsoIdpsFetching,
    selectSsoLoginRequired
} from '../userSlice';
import {selectConfig, selectRefererUrl} from '../../appSlice';
import {PASSWORD_RECOVERY_PATH} from '../../../../constants';
import Buttons from './Buttons/Buttons';
import useErrorNotification from '../../../hooks/useErrorNotification';
import './loginForms.less';

export default function LoginForms() {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authError = useSelector(selectAuthError);
    const refererUrl = useSelector(selectRefererUrl);
    const emailSsoIdpsFetchedFor = useSelector(selectEmailSsoIdpsFetchedFor);
    const isSsoIdpsFetching = useSelector(selectIsSsoIdpsFetching);
    const ssoLoginRequired = useSelector(selectSsoLoginRequired);
    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
    });
    const [wasChangedFields, setWasChangedFields] = useState({
        email: false,
        password: false
    });
    const config = useSelector(selectConfig);

    const shouldTrySSO = config.VS_ENV !== 'development' && config.VS_ENV !== 'automation';
    const shouldShowPassword = !!loginForm.email && (shouldTrySSO ? loginForm.email === emailSsoIdpsFetchedFor : true);
    const isSsoLoginRequiredForCurrentEmail = ssoLoginRequired && loginForm.email === emailSsoIdpsFetchedFor;
    useErrorNotification(authError, {}, {
        position: 'top-center'
    });

    function onLoginFormChange({target}) {
        setLoginForm({
            ...loginForm,
            [target.name]: target.value
        });
        setWasChangedFields({
            ...wasChangedFields,
            [target.name]: true
        });
    }

    async function submit(e) {
        e.preventDefault();
        if (shouldShowPassword) {
            const wasAuthSuccessful = await dispatch(authenticate({credentials: loginForm}));
            if (wasAuthSuccessful && refererUrl) {
                // if it's a signer ui URL we want to refresh the page to make sure the server side function handle this request
                if (refererUrl.includes(NEW_DIRECT_SIGN_PATH)) {
                    window.location.href = refererUrl;
                } else {
                    navigate(refererUrl);
                }
            }
        } else if (shouldTrySSO) {
            dispatch(fetchSsoIdps(loginForm.email));
        }
    }

    useEffect(() => {
        setLoginForm({
            email: '',
            password: ''
        });
    }, []);

    return (
        <div className="login-forms">
            <Notifications />
            <Loading isLoading={isSsoIdpsFetching} />
            <form className="credentials-form" onSubmit={submit}>
                <EmailForm
                    email={loginForm.email}
                    wasChanged={wasChangedFields.email}
                    onLoginFormChange={onLoginFormChange}
                />
                <PasswordForm
                    value={loginForm.password}
                    wasChanged={wasChangedFields.password}
                    onChange={onLoginFormChange}
                    classNames="login-password-form"
                    autocomplete="current-password"
                    label={`${intl.formatMessage({id: 'esl.generic.password', defaultMessage: ''})} *`}
                    isVisible={shouldShowPassword && !isSsoLoginRequiredForCurrentEmail}
                />
                <Buttons
                    loginForm={loginForm}
                    isLoginButtonVisible={shouldShowPassword}
                    ssoLoginRequired={isSsoLoginRequiredForCurrentEmail}
                />
            </form>
            <SsoForm
                wasSsoFetchedForEmail={shouldShowPassword}
                ssoLoginRequired={isSsoLoginRequiredForCurrentEmail}
            />
            <div className="password-recovery-link-container">
                <Link
                    to={`${PASSWORD_RECOVERY_PATH}?email=${loginForm.email}`}
                    className="password-recovery-link"
                    data-wdio="test-login-recovery-link"
                >
                    {intl.formatMessage({id: 'esl.partials.link_forgot_password.forgot_your_password', defaultMessage: ''})}
                </Link>
            </div>
        </div>
    );
}
