/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import get from 'lodash.get';
import mergeWith from 'lodash.mergewith';
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import set from 'lodash.set';
import httpClient from '../httpClient';
import {
    BASE_PATH, DEFAULT_FONT_SIZE, FILE_UPLOAD_TIMEOUT_MS, PACKAGE_TYPES, TRANSACTION_STATUSES
} from '../../../constants';
import getDocumentsFormDataChunks from '../getDocumentsFormDataChunks';
import {getErrorMessage} from './error';
import {sequentialAsync} from '../helpers';

const PACKAGE_PATH = `${BASE_PATH}/proxy/packages/`;
const formDataHeaders = {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data'
};

export const fetchPackage = ({packageId}) => {
    return httpClient.get(`${PACKAGE_PATH}${packageId}`);
};

export const updatePackage = (packageId, payload) => {
    return httpClient.put(`${PACKAGE_PATH}${packageId}`, payload);
};

export const deletePackagePermanently = (packageId) => {
    return httpClient.delete(`${PACKAGE_PATH}${packageId}`);
};

export const restorePackage = (packageId) => {
    return httpClient.put(`${PACKAGE_PATH}${packageId}`, {
        trashed: false
    });
};

export const archivePackage = (packageId) => {
    return httpClient.put(`${PACKAGE_PATH}${packageId}`, {
        status: TRANSACTION_STATUSES.ARCHIVED
    });
};

export const unArchivePackage = (packageId) => {
    return httpClient.put(`${PACKAGE_PATH}${packageId}`, {
        status: TRANSACTION_STATUSES.COMPLETED
    });
};
// docs
export const createPackageDocuments = (packageId) => (formData) => {
    return httpClient.post(`${PACKAGE_PATH}${packageId}/documents`, formData, {
        headers: formDataHeaders,
        timeout: FILE_UPLOAD_TIMEOUT_MS
    });
};

export const updatePackageDocuments = (packageId, payload) => {
    return httpClient.put(`${PACKAGE_PATH}${packageId}/documents`, payload);
};

export const updatePackageDocument = (packageId, documentId, payload) => {
    return httpClient.post(`${PACKAGE_PATH}${packageId}/documents/${documentId}`, payload);
};

export const deletePackageDocument = (packageId, documentId) => {
    return httpClient.delete(`${PACKAGE_PATH}${packageId}/documents/${documentId}`);
};

// reminders
export const createPackageReminder = (packageId, payload) => {
    return httpClient.post(`${PACKAGE_PATH}${packageId}/reminders`, {
        ...payload,
        reminders: [],
        packageId
    });
};

export function mergePayload(objects, skipKeys = []) {
    function customizer(objValue, srcValue, key) {
        return skipKeys.includes(key) ? objValue : undefined;
    }

    return mergeWith({}, ...objects, customizer);
}

export function uploadDocumentChunks(packageId, chunks) {
    return sequentialAsync(chunks, createPackageDocuments(packageId));
}

export async function createPackageRequest({
    defaultData, template, packageData, documents, packageSettings, onDocumentsUpload, user
}) {
    const documentsError = [];
    let payload = defaultData;

    if (!isEmpty(template)) {
        const filteredTemplateData = pick(template, Object.keys(defaultData));
        payload = mergePayload([payload, filteredTemplateData], ['name', 'type']);
    }
    payload = mergePayload([payload, packageData], !isEmpty(template) ? ['senderVisible', 'ada'] : []);
    const updatedSettings = get(payload, 'settings.ceremony', {});
    payload.data = {
        ...payload.data,
        shouldApplySigningOrderByDefault: isEmpty(template),
        shouldApplyChangeSignerByDefault: isEmpty(template)
    };
    if (payload.type === PACKAGE_TYPES.PACKAGE) {
        if (!isEmpty(template)) {
            set(payload, 'settings.ceremony.fontSize', get(template, 'settings.ceremony.fontSize'));
        } else {
            const {fontSizeSelected, fontSize} = get(user, 'data.personal_preferences', {});
            const personalPrefetencesFontSize = fontSizeSelected ? fontSize
                : (get(updatedSettings, 'ceremony.fontSize', null) || DEFAULT_FONT_SIZE);
            set(payload, 'settings.ceremony.fontSize', personalPrefetencesFontSize);
        }
    }
    set(payload, 'settings.ceremony', updatedSettings);

    const url = isEmpty(template) ? 'packages' : `packages/${template.id}/clone`;
    const result = await httpClient.post(`${BASE_PATH}/proxy/${url}`, payload);
    const packageId = get(result, 'data.id', null);
    const mergedPackageCeremony = {...packageSettings, ...packageData?.settings?.ceremony};

    payload.settings.ceremony = mergedPackageCeremony;

    if (!isEmpty(documents) && packageId) {
        const chunks = getDocumentsFormDataChunks(documents);

        try {
            const documentsResult = await uploadDocumentChunks(packageId, chunks);
            const failedDocuments = documentsResult.errors;

            if (!isEmpty(failedDocuments)) {
                onDocumentsUpload(failedDocuments.map(getErrorMessage));
            } else {
                onDocumentsUpload([]);
            }
        } catch (err) {
            documentsError.push(err);
            onDocumentsUpload(documentsError);
        }
    }

    return packageId;
}
export async function sendBulk({
    defaultData,
    template,
    packageData,
    bulkCSV
}) {
    let payload = defaultData;
    const filteredTemplateData = pick(template, Object.keys(defaultData));
    payload = mergePayload([payload, filteredTemplateData], ['name', 'type']);
    payload = mergePayload([payload, packageData], ['senderVisible', 'ada']);
    const formData = new window.FormData();

    formData.append('file', bulkCSV);
    formData.append('payload', JSON.stringify(payload));
    formData.append('package_name', payload.name);
    formData.append('package_description', payload.description);
    formData.append('toggle-visibility', 'on');
    formData.append('package_due', payload.due);
    formData.append('package_language', payload.language);
    formData.append('inperson_checkbox', get(payload, 'settings.ceremony.inPerson', false) ? 'on' : 'off');
    formData.append('package_manualComplete', 'on');
    formData.append('notarized_checkbox', payload.notarized ? 'on' : 'off');
    formData.append('package_emailMessage', payload.emailMessage);

    const result = await httpClient.post(`${PACKAGE_PATH}${template.id}/bulk_send`, formData, {
        headers: formDataHeaders,
        timeout: FILE_UPLOAD_TIMEOUT_MS
    });

    const resultData = get(result, 'data', null);
    const parsedResultData = resultData ? JSON.parse(resultData.replace(/<pre>|<\/pre>/g, '')) : null;
    return get(parsedResultData, 'id', null);
}

export async function searchPackageByName({packageName, type, visibility}) {
    const res = await httpClient.get(
        `${BASE_PATH}/proxy/packages?search=${packageName}&searchtype=exactname&visibility=${visibility}&type=${type}`
    );
    return res.data;
}

export function savePackageDataAttr({packageData, data}) {
    return httpClient.put(`${PACKAGE_PATH}${packageData.id}`, {
        data: {
            ...packageData.data,
            ...data
        }
    });
};
