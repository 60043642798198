/* eslint-disable import/prefer-default-export */
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useMutation} from '@apollo/client';
import classNames from 'classnames';
import {FormHelperText} from '@material-ui/core';
import {useFindAndSetFirstPendingDocumentAsActive} from '@components/SignerUi/hooks/useFindAndSetFirstPendingDocumentAsActive';
import {AppButton} from '../../../Core/buttons/AppButton/AppButton';
import AppInput from '../../../Core/inputs/AppInput/AppInput';
import {UnderlinedButton} from '../../../Core/company/buttons/UnderlinedButton/UnderlinedButton';
import {SEND_AFFIDAVIT_SMS} from '../../../../../gql/mutations';
import {useSwitchRole} from '../../hooks/mutations';
import {useCeremony} from '../../hooks/queries';
import {getErrorMessage} from '../../utils/errors';
import {Dialog, DialogButton} from '../../../Core/company/modals/Dialog';
import {startInPersonSigning} from '../../signerUiSlice';
import {MAX_PASSCODE_LENGTH, MIN_PASSCODE_LENGTH} from '../../Login/constants';
import './smsDialogComponent.less';

export const SmsDialogComponent = ({
    isOpen,
    close,
    signer
}) => {
    const {
        signerId, id, phoneNumberEnding
    } = signer;
    const intl = useIntl();
    const dispatch = useDispatch();
    const {transaction} = useCeremony();
    const [switchRole, {error}] = useSwitchRole();
    const [resendClicked, setResendClicked] = useState();
    const [passcode, setPasscode] = useState('');
    const [sendPasscode, {loading: resendLoading, error: resendError}] = useMutation(SEND_AFFIDAVIT_SMS);
    const onResendPasscode = () => {
        setResendClicked(true);
        if (!resendClicked) {
            sendPasscode({variables: {transactionId: transaction.id, roleId: id, force: true}});
        }
    };
    const isResendSuccessful = resendClicked && !resendError && !resendLoading;
    const {findAndSetFirstPendingDocumentAsActive} = useFindAndSetFirstPendingDocumentAsActive();

    async function onConfirm(e) {
        e.preventDefault();
        if (passcode) {
            const data = await switchRole({
                roleId: signerId,
                payload: {sms: {passcode}}
            });

            if (data?.switchRole?.sessionId) {
                await findAndSetFirstPendingDocumentAsActive();

                dispatch(startInPersonSigning({
                    isOwner: signer.isOwner,
                    canSign: signer.canSign
                }));
            }
            setResendClicked(false);
        }
    }

    function onChange({target}) {
        setPasscode(target.value);
    }

    useEffect(() => {
        if (isOpen) {
            sendPasscode({variables: {transactionId: transaction.id, roleId: id, force: true}});
        }
    }, [isOpen]);

    return (
        <Dialog
            isOpen={isOpen}
            className="sms-verification-modal"
        >
            <form onSubmit={onConfirm}>
                <h2 className="title">
                    {intl.formatMessage({id: 'oss.components.SmsAffidavitDialog.title', defaultMessage: ''})}
                </h2>
                <div className="content">
                    <p>{intl.formatMessage({id: 'oss.components.SmsAffidavitDialog.content', defaultMessage: ''}, {phoneNumberEnding})}</p>
                    <AppInput
                        value={passcode}
                        onChange={onChange}
                        className="passcode"
                        minLength={MIN_PASSCODE_LENGTH}
                        maxLength={MAX_PASSCODE_LENGTH}
                        label={intl.formatMessage({id: 'oss.components.SmsLoginView.passcode', defaultMessage: ''})}
                        helperText={error && intl.formatMessage({
                            id: `oss.${getErrorMessage(error).messageKey}`,
                            defaultMessage: intl.formatMessage({id: `esl.${getErrorMessage(error).messageKey}`, defaultMessage: ''})
                        })}
                        error={error}
                        autoFocus
                    />
                    <UnderlinedButton
                        className={classNames('resend-button', {success: isResendSuccessful})}
                        onClick={onResendPasscode}
                        disabled={isResendSuccessful}
                    >
                        {intl.formatMessage({
                            id: resendClicked ? 'oss.components.SmsLoginView.notificationMessage' : 'oss.components.SmsLoginView.resendLink',
                            defaultMessage: ''
                        })}
                    </UnderlinedButton>
                    {resendError && (
                        <FormHelperText error>
                            {intl.formatMessage({id: `esl.${getErrorMessage(resendError).messageKey}`, defaultMessage: ''})}
                        </FormHelperText>
                    )}
                </div>
                <div className="actions">
                    <DialogButton onClick={close}>
                        {intl.formatMessage({id: 'oss.components.SmsLoginView.ResendDialog.cancelButton', defaultMessage: ''})}
                    </DialogButton>
                    <AppButton
                        type="submit"
                        disabled={passcode.length < MIN_PASSCODE_LENGTH || passcode.length > MAX_PASSCODE_LENGTH || !passcode.length}
                    >
                        {intl.formatMessage({id: 'oss.components.SmsAffidavitDialog.enter.button', defaultMessage: ''})}
                    </AppButton>
                </div>
            </form>
        </Dialog>

    );
};
