import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash.isempty';
import Button from '@material-ui/core/Button';
import {HiOutlineCheck} from '@react-icons/all-files/hi/HiOutlineCheck';
import {HiOutlineX} from '@react-icons/all-files/hi/HiOutlineX';
import {CgRadioCheck} from '@react-icons/all-files/cg/CgRadioCheck';
import {CgRadioChecked} from '@react-icons/all-files/cg/CgRadioChecked';
import classnames from 'classnames';
import Loading from '../../shared/Loading/Loading';
import InfoLink from '../../shared/InfoLink/InfoLink';
import PasswordForm from '../../shared/PasswordForm/PasswordForm';
import {
    fetchPasswordPolicy,
    savePassword,
    selectIsPasswordPolicyFetching,
    selectIsPasswordSaving,
    selectPasswordPolicy,
    selectPasswordSaveError
} from './passwordSlice';
import useErrorNotification from '../../../hooks/useErrorNotification';
import useNotifications from '../../../hooks/useNotifications';
import PageTitle from '../../shared/PageTitle';
import './changePassword.less';

export default function ChangePassword({
    onChange = () => {},
    setIsValidPassword,
    isRegistration,
    isResetPassword,
    updatePassword,
    passwordPolicy,
    registration
}) {
    const intl = useIntl();
    const {showSuccessAlert} = useNotifications();
    const dispatch = useDispatch();
    const policy = useSelector(selectPasswordPolicy);
    const {
        length,
        includeUppercase,
        includeLowercase,
        includeNumbers,
        includeSpecialCharacters,
        requiredRules
    } = passwordPolicy || policy;
    const isPasswordPolicyFetchings = useSelector(selectIsPasswordPolicyFetching);
    const isPasswordSaving = useSelector(selectIsPasswordSaving);
    const passwordSaveError = useSelector(selectPasswordSaveError);
    const [wasConfirmedPasswordChanged, setWasConfirmedPasswordChanged] = useState(false);
    const [form, setForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmedPassword: ''
    });

    useErrorNotification(passwordSaveError);

    function renderCheckIcon(value) {
        return value
            ? <HiOutlineCheck className="check-icon success" />
            : <HiOutlineX className="check-icon failed" />;
    }

    function renderSubCheckIcon(value) {
        return value
            ? <CgRadioChecked className="sub-check-icon success" />
            : <CgRadioCheck className="sub-check-icon failed" />;
    }

    function changeForm({target}) {
        setForm({
            ...form,
            [target.name]: target.value
        });
        onChange({target});

        if (target.name === 'confirmedPassword' && !wasConfirmedPasswordChanged) {
            setWasConfirmedPasswordChanged(true);
        }
    }

    function getHasMinimumCharacters() {
        return length && form.newPassword.length >= length;
    }

    function getHasUppercaseCharacters() {
        const regExp = /[A-Z]/;
        return includeUppercase && regExp.test(form.newPassword);
    }

    function getHasLowercaseCharacters() {
        const regExp = /[a-z]/;
        return includeLowercase && regExp.test(form.newPassword);
    }

    function getHasNumbers() {
        const regExp = /[0-9]/;
        return includeNumbers && regExp.test(form.newPassword);
    }

    function getHasSpecialCharacters() {
        const regExp = /[~ ! @ # $ % ^ & *]/;
        return includeSpecialCharacters && regExp.test(form.newPassword);
    }

    const hasMinimumCharacters = getHasMinimumCharacters();
    const hasUppercaseCharacters = getHasUppercaseCharacters();
    const hasLowercaseCharacters = getHasLowercaseCharacters();
    const hasNumbers = getHasNumbers();
    const hasSpecialCharacters = getHasSpecialCharacters();

    function getHasAllRequiredRules() {
        const passedChecks = [
            hasUppercaseCharacters,
            hasLowercaseCharacters,
            hasNumbers,
            hasSpecialCharacters
        ].filter((isRulePassed) => isRulePassed);

        return passedChecks.length >= requiredRules;
    }

    const hasAllRequiredRules = getHasAllRequiredRules();
    const isConfirmedPasswordWrong = form.newPassword !== form.confirmedPassword;
    const shouldAllowUpdatePassword = !isConfirmedPasswordWrong && hasMinimumCharacters && hasAllRequiredRules;
    const shouldDisableSave = isResetPassword ? !shouldAllowUpdatePassword
        : (form.currentPassword === '' || isConfirmedPasswordWrong || !hasMinimumCharacters || !hasAllRequiredRules);
    const containerClassName = classnames('account-change-password account-page change-password', {
        'reset-password-page': isResetPassword
    });

    async function save() {
        if (isResetPassword) {
            updatePassword(form, shouldAllowUpdatePassword);
        } else {
            const wasSuccessful = await dispatch(savePassword({
                newPassword: form.newPassword,
                password: isRegistration ? form.confirmedPassword : form.currentPassword

            }));
            if (wasSuccessful) {
                showSuccessAlert(intl.formatMessage({id: 'esl.feedback.password_updated.success', defaultMessage: ''}));
            }
        }
    }

    useEffect(() => {
        if (isEmpty(passwordPolicy)) {
            dispatch(fetchPasswordPolicy());
        }
    }, []);

    useEffect(() => {
        if (isRegistration) {
            setIsValidPassword(hasAllRequiredRules && !isConfirmedPasswordWrong);
        }
    }, [isRegistration, hasAllRequiredRules, isConfirmedPasswordWrong]);

    return (
        <div className={containerClassName}>
            <PageTitle pageTitle={intl.formatMessage({id: 'page.account.changePassword', defaultMessage: ''})} />
            <div className="account-page-title">
                {intl.formatMessage({id: 'page.account.changePassword', defaultMessage: ''})}
            </div>
            <div className="account-page-body">
                <Loading isLoading={isPasswordPolicyFetchings || isPasswordSaving} />
                {!isResetPassword && !isRegistration && (
                    <div className="change-password-row current-password">
                        <div className="input-container">
                            <PasswordForm
                                value={form.currentPassword}
                                onChange={changeForm}
                                label={intl.formatMessage({id: 'esl.changepassword.current_password', defaultMessage: ''})}
                                isVisible
                                name="currentPassword"
                            />
                        </div>
                    </div>
                )}
                <div className="change-password-row">
                    <div className="password-strangth-container">
                        <div className="password-strangth-label">
                            {intl.formatMessage({id: 'page.changePassword.passwordStrength', defaultMessage: ''})}
                        </div>
                        <div className="password-strangth-checks">
                            <div className="password-strangth-check">
                                <div className="icon">
                                    {renderCheckIcon(hasMinimumCharacters)}
                                </div>
                                <div className="label">
                                    {intl.formatMessage({id: 'page.changePassword.passwordLength', defaultMessage: ''}, {
                                        length
                                    })}
                                </div>
                            </div>
                            {!!requiredRules && (
                                <div className="password-strangth-check">
                                    <div className="icon">
                                        {renderCheckIcon(hasAllRequiredRules)}
                                    </div>
                                    <div className="label">
                                        {intl.formatMessage({id: 'page.changePassword.requiredRules', defaultMessage: ''}, {
                                            requiredRules
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="password-strangth-sub-checks-container">
                                {includeUppercase && (
                                    <div className="password-strangth-sub-check">
                                        <div className="icon">
                                            {renderSubCheckIcon(hasUppercaseCharacters)}
                                        </div>
                                        <div className="label">
                                            {intl.formatMessage({id: 'page.changePassword.includeUppercase', defaultMessage: ''})}
                                        </div>
                                    </div>
                                )}
                                {includeLowercase && (
                                    <div className="password-strangth-sub-check">
                                        <div className="icon">
                                            {renderSubCheckIcon(hasLowercaseCharacters)}
                                        </div>
                                        <div className="label">
                                            {intl.formatMessage({id: 'page.changePassword.includeLowercase', defaultMessage: ''})}
                                        </div>
                                    </div>
                                )}
                                {includeNumbers && (
                                    <div className="password-strangth-sub-check">
                                        <div className="icon">
                                            {renderSubCheckIcon(hasNumbers)}
                                        </div>
                                        <div className="label">
                                            {intl.formatMessage({id: 'page.changePassword.includeNumbers', defaultMessage: ''})}
                                        </div>
                                    </div>
                                )}
                                {includeSpecialCharacters && (
                                    <div className="password-strangth-sub-check">
                                        <div className="icon">
                                            {renderSubCheckIcon(hasSpecialCharacters)}
                                        </div>
                                        <div className="label">
                                            {intl.formatMessage({id: 'page.changePassword.includeSpecialCharacters', defaultMessage: ''})}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="input-container new-password">
                        <PasswordForm
                            label={registration ? `${intl.formatMessage({id: 'esl.generic.password', defaultMessage: ''})} *`
                                : `${intl.formatMessage({id: 'esl.changepassword.new_password', defaultMessage: ''})}`}
                            placeholder={registration && '••••••••'}
                            value={form.newPassword}
                            onChange={changeForm}
                            isVisible
                            variant={registration ? 'standard' : 'outlined'}
                            name="newPassword"
                        />
                    </div>
                    <div className="input-container confirm-password">
                        <PasswordForm
                            label={registration ? `${intl.formatMessage(
                                {id: 'esl.generic.confirm_password', defaultMessage: ''}
                            )} *`
                                : `${intl.formatMessage(
                                    {id: 'esl.changepassword.confirm', defaultMessage: ''}
                                )}`}
                            placeholder={registration && '••••••••'}
                            value={form.confirmedPassword}
                            onChange={changeForm}
                            isVisible
                            variant={registration ? 'standard' : 'outlined'}
                            name="confirmedPassword"
                            error={isConfirmedPasswordWrong && wasConfirmedPasswordChanged && intl.formatMessage({
                                id: 'esl.validation.password_match',
                                defaultMessage: ''
                            })}
                        />
                    </div>
                </div>
                {!isRegistration && (
                    <div className="button-container">
                        <Button onClick={save} classes={{root: 'save-btn'}} disabled={shouldDisableSave}>
                            {intl.formatMessage({id: 'esl.generic.submit', defaultMessage: ''})}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
