import get from 'lodash.get';
import {USER_TYPES} from '../../constants';
import PERMISSIONS from './permissionsConstants';

export function hasPermission(permissions = [], key) {
    return permissions.includes(key);
}

function hasFeature(features = {}, key) {
    return get(features, key, false);
}

const permissionsService = {
    hasSubscriptionPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.sender_admin.subscription),

    hasReportPagePermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.sender_admin.reports),

    hasCustomFieldPermission: ({permissions, features}) => hasPermission(permissions, PERMISSIONS.sender_admin.custom_fields)
        && hasFeature(features, 'customFields'),

    hasEventNotificationPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.sender_admin.event_notification),

    hasChangeSignerPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.transaction.change_signer),

    hasCustomizationPermission: ({permissions, features}) => hasPermission(permissions, PERMISSIONS.sender_admin.customization)
        && hasFeature(features, 'enableSignerUi'),

    hasUserManagementPermission: ({
        features, permissions, hasUserPermissionOnRoot
    }) => hasPermission(permissions, PERMISSIONS.sender_admin.users)
        || (permissionsService.isSubaccountEnabled({features}) && hasUserPermissionOnRoot === true),

    hasDataManagementPermission: ({permissions, features}) => hasPermission(permissions, PERMISSIONS.sender_admin.data_management)
        && hasFeature(features, 'selfServiceDataManagement'),

    isSubaccountEnabled: ({features}) => hasFeature(features, 'subAccounts'),

    isAdminPermission: (permission) => permission.includes(PERMISSIONS.groups.heading)
        || (permission !== PERMISSIONS.sender_admin.reports && permission.includes(PERMISSIONS.sender_admin.heading)),

    hasAdminPermission: ({
        features, permissions, hasUserPermissionOnRoot
    }) => permissions.filter((permission) => permissionsService.isAdminPermission(permission)).length > 0
        || (permissionsService.isSubaccountEnabled({features}) && hasUserPermissionOnRoot === true),

    hasShareTemplatePermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.templates_layouts.share_templates),

    hasInPersonSigningPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.transaction.in_person),

    hasShareLayoutPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.templates_layouts.share_layouts),

    hasPasswordSettingsPermission: ({permissions, features}) => hasPermission(permissions, PERMISSIONS.sender_admin.security_settings)
        && !hasFeature(features, 'ssoLogin') && hasFeature(features, 'passwordManagement'),

    hasTransactionPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.transaction.transaction),

    hasRolesPermission: ({features, permissions, hasUserPermissionOnRoot}) => hasPermission(permissions, PERMISSIONS.sender_admin.role)
        || (permissionsService.isSubaccountEnabled({features}) && hasUserPermissionOnRoot === true),

    hasNotaryPermissions: ({permissions}) => hasPermission(permissions, PERMISSIONS.sender_admin.notary),

    hasApiAccessPermissions: ({permissions, features}) => hasPermission(permissions, PERMISSIONS.sender_admin.api_access)
        && (hasFeature(features, 'apiKey') || hasFeature(features, 'apiToken')),

    hasGroupManagementPermission: ({permissions}) => hasPermission(permissions, PERMISSIONS.groups.group_signing_management),

    hasAccountGroupManagementPermission: ({user = {}, features}) => {
        const isManager = user.type === USER_TYPES.MANAGER;
        const hasMembership = !!user.memberships?.length;
        const isFeatureEnabled = hasFeature(features, 'groups');
        const isGroupManager = user.memberships?.some(({memberType}) => memberType.toLowerCase() === 'manager');

        return !isManager && hasMembership && isFeatureEnabled && isGroupManager;
    },

    isEmailDeliveryEnabled: ({features}) => hasFeature(features, 'deliverDocumentsByEmail'),

    hasAssignDelegatedPermission: ({user}) => get(user, 'data.isAssignDelegatesAllowed', true),
    hasUploadSignaturePermission: ({user, features}) => features.uploadSignatureImage && get(user, 'data.personal_preferences', {}).fromFile

};

export default permissionsService;
